import revive_payload_client_7IEBcdku6T from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nOGdlgZkYv from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5VQDkLdEhx from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Gre507WS6p from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_moiaYohk6B from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ASa3DfiXlo from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wBsWAVMT8C from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/apps/kinoheld/.nuxt/components.plugin.mjs";
import prefetch_client_fDhBTszezu from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/apps/kinoheld/.nuxt/formkitPlugin.mjs";
import plugin_yPH0nd7OcY from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/nuxt-graphql-client@0.2.36_graphql-tag@2.12.6_typescript@5.5.4/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import composition_GTxKeb5Qzl from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_vue-router@4.4.3_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_FgLpto0kRT from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_vue-router@4.4.3_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/kinoheld/live/2024-11-14_15-24-05_5970f26/layers/base/plugins/sentry.client.ts";
export default [
  revive_payload_client_7IEBcdku6T,
  unhead_nOGdlgZkYv,
  router_5VQDkLdEhx,
  payload_client_Gre507WS6p,
  navigation_repaint_client_moiaYohk6B,
  check_outdated_build_client_ASa3DfiXlo,
  chunk_reload_client_wBsWAVMT8C,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fDhBTszezu,
  formkitPlugin_pZqjah0RUG,
  plugin_yPH0nd7OcY,
  composition_GTxKeb5Qzl,
  i18n_FgLpto0kRT,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV
]