import getUtmParams from '@base/utils/get-utm-params'

// Helper to add UTM params to URL
function addUtmToPath(
  path: string,
  urlParams: Record<string, string | undefined>
) {
  if (Object.keys(urlParams).length === 0) {
    return path
  }
  const definedParams = Object.fromEntries(
    Object.entries(urlParams).filter(([_, value]) => value !== undefined)
  ) as Record<string, string>

  const params = new URLSearchParams(definedParams)
  return `${path}?${params.toString()}`
}

export default defineNuxtRouteMiddleware((to) => {
  // Get UTM params from URL and state
  const urlUtmParams = getUtmParams(
    to.query as Record<string, string | undefined>
  )
  const utmState = useUtmState()
  const utmParams = { ...utmState.value, ...urlUtmParams }

  /**
   * should redirect old urls
   * /Kino-Grevenbroich/Grefi%2520Kino%2520Grevenbroich/vorstellung/140353
   * to
   * /kino/grevenbroich/grefi-kino-grevenbroich/vorstellung/140353
   */
  if (to.path.startsWith('/Kino-')) {
    const parts = to.path.split('/')
    const citySlug = parts[1].toLowerCase().replace('kino-', '')
    const cinemaSlug = decodeURIComponent(decodeURIComponent(parts[2]))
      .toLowerCase()
      .replace(/\s+/g, '-')
    const subPath = parts[3] || ''
    const id = parts[4] || ''

    const newPath = ['kino', citySlug, cinemaSlug, subPath, id]
      .filter(Boolean)
      .join('/')

    if (!subPath && !id) {
      return navigateTo(addUtmToPath(`/${newPath}/vorstellungen`, utmParams))
    } else {
      return navigateTo(addUtmToPath(`/${newPath}`, utmParams))
    }
  }

  // should redirect en urls from google
  // https://www.kinoheld.de/cinema/muenchen/royal-filmpalast/show/436270
  // to
  // https://www.kinoheld.de/kino/muenchen/royal-filmpalast/vorstellung/436270
  if (to.path.startsWith('/cinema/')) {
    const parts = to.path.split('/')
    const citySlug = parts[2].toLowerCase()
    const cinemaSlug = parts[3].toLowerCase()
    const id = parts[5]

    return navigateTo(
      addUtmToPath(
        `/kino/${citySlug}/${cinemaSlug}/vorstellung/${id}`,
        utmParams
      )
    )
  }

  // should redirect order urls
  // https://www.kinoheld.de/account/order/2405980087168/NzI0Nzc2.MTE2NDg2MTI1OTgw.NTk4MTExMjczODA/?lang=de
  if (to.path.startsWith('/account/order/')) {
    const parts = to.path.split('/')

    return navigateTo(
      addUtmToPath(`/konto/bestellung/${parts[3]}/${parts[4]}`, utmParams)
    )
  }
})
